import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ReactNode } from 'react';
import { alpha, useTheme } from '@mui/material';

interface Props {
  cancelButtonText: string,
  confirmButtonText: string,
  title?: string,
  helperText?: string,
  isOpen: boolean,
  close: Function,
  confirm: Function,
  children?: ReactNode
}

const DialogBodyComponent = (props: Props) => {
  const {
    cancelButtonText,
    confirmButtonText,
    title, helperText,
    isOpen,
    close,
    confirm,
    children,
  } = props;

  const theme = useTheme();

  const handleClose = () => {
    close(false);
  };

  const handleConfirm = () => {
    confirm();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{
          color: theme.palette.primary.main,
          paddingBottom: '0.25rem',
        }}
      >
        { title }
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            fontSize: '12px',
          }}
        >
          { helperText }
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          disableElevation
          size="small"
          sx={{
            color: theme.palette.primary.main,
            border: `1px ${theme.palette.primary.main} solid`,
            borderRadius: '2rem',
            backgroundColor: '#fff',
            marginBottom: '0.5rem',
            fontSize: {
              lg: '12px', xl: '14px',
            },
            '&:hover': {
              backgroundColor: 'lightgray',
            },
          }}
          onClick={handleClose}
        >
          { cancelButtonText }
        </Button>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          disableElevation
          size="small"
          sx={{
            borderRadius: '2rem',
            backgroundColor: theme.palette.primary.main,
            marginBottom: '0.5rem',
            fontSize: {
              lg: '12px', xl: '14px',
            },
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.8),
            },
          }}
          onClick={handleConfirm}
        >
          { confirmButtonText }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { DialogBodyComponent };
