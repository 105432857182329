import {
  Avatar,
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, {
} from 'react';
import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import TrendingUp from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { applyCurrencyMask } from '../../../utils/util';

interface ProjectsValue {
  cadastro: number;
  analiseSoltech: number;
  analiseBancos: number;
  escolhaCondicoes: number;
  condicoesEscolhidas: number;
  aprovados: number;
  reprovados: number;
  contratados: number;
}

export interface DonutData {
  projectValue: ProjectsValue,
  totalValueMonth: string,
  totalProjectsMonth: number,
  monthProjectsDifference: number,
  monthDifference: string,
}

export const defaultDonutData: DonutData = {
  projectValue: {
    cadastro: 0,
    analiseSoltech: 0,
    analiseBancos: 0,
    escolhaCondicoes: 0,
    condicoesEscolhidas: 0,
    aprovados: 0,
    reprovados: 0,
    contratados: 0,
  },
  totalValueMonth: '0,00',
  totalProjectsMonth: 0,
  monthProjectsDifference: 0,
  monthDifference: '0,00',
};

interface DonutProps {
  donutData: DonutData
}
export function Donut(props: DonutProps) {
  const {
    donutData,
  }: DonutProps = props;
  const theme = useTheme();
  // Chart Options
  const chartOptions: ApexOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '55%',
        },
      },
    },
    colors: [theme.palette.primary.light, theme.palette.primary.main,
      'rgba(145,110,110,0.73)',
      '#5c6ac0', '#bb651f', '#5fc922', '#ff0000',
      '#22ff00'],
    dataLabels: {
      enabled: true,
      formatter(val) {
        return `${Math.round(Number(val))}%`;
      },
      style: {
        colors: ['white'],
      },
      background: {
        enabled: true,
        foreColor: 'white',
        padding: 8,
        borderRadius: 4,
        borderWidth: 0,
        opacity: 0.3,
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 1,
          color: 'black',
          opacity: 0.5,
        },
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        color: 'black',
        opacity: 0.5,
      },
    },
    fill: {
      opacity: 1,
    },
    labels: [`Cadastro -  Valor total: ${donutData.projectValue?.cadastro ? applyCurrencyMask(String(donutData.projectValue?.cadastro)) : 'R$0,00'} - Total de projetos: `,
      `Análise - Soltech -  Valor total: ${donutData.projectValue?.analiseSoltech ? applyCurrencyMask(String(donutData.projectValue?.analiseSoltech)) : 'R$0,00'} - Total de projetos: `,
      `Análise - Bancos -  Valor total: ${donutData.projectValue?.analiseBancos ? applyCurrencyMask(String(donutData.projectValue?.analiseBancos)) : 'R$0,00'} - Total de projetos: `,
      `Aguardando escolha das condições -  Valor total: ${donutData.projectValue?.escolhaCondicoes ? applyCurrencyMask(String(donutData.projectValue?.escolhaCondicoes)) : 'R$0,00'} - Total de projetos: `,
      `Condições escolhidas -  Valor total: ${donutData.projectValue?.condicoesEscolhidas ? applyCurrencyMask(String(donutData.projectValue?.condicoesEscolhidas)) : 'R$0,00'} - Total de projetos: `,
      `Aprovados -  Valor total: ${donutData.projectValue?.aprovados ? applyCurrencyMask(String(donutData.projectValue?.aprovados)) : 'R$0,00'} - Total de projetos: `,
      `Reprovados -  Valor total: ${donutData.projectValue?.reprovados ? applyCurrencyMask(String(donutData.projectValue?.reprovados)) : 'R$0,00'} - Total de projetos: `,
      `Contratados -  Valor total: ${donutData.projectValue?.contratados ? applyCurrencyMask(String(donutData.projectValue?.contratados)) : 'R$0,00'} - Total de projetos: `],
    legend: {
      customLegendItems: ['Cadastro', 'Análise Soltech', 'Análise Bancos', 'Escolhendo condições', 'Condições escolhidas', 'Aprovados', 'Reprovados', 'Contratados'],
      labels: {
        colors: 'black',
      },
      position: 'bottom',
      show: true,
    },
    stroke: {
      width: 0,
    },
    theme: {
      mode: theme.palette.mode,
    },
  };
  // ============================================================================================
  const chartSeries = [
    donutData.projectValue.cadastro,
    donutData.projectValue.analiseSoltech,
    donutData.projectValue.analiseBancos,
    donutData.projectValue.escolhaCondicoes,
    donutData.projectValue.condicoesEscolhidas,
    donutData.projectValue.aprovados,
    donutData.projectValue.reprovados,
    donutData.projectValue.contratados,
  ];

  const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    padding: '0.4rem',
    margin: '0 auto',
  };

  const monthDifferenceToNumber = () => {
    const num = donutData.monthDifference.replace(/[^0-9,-]/g, '');
    return Number(num.replace(/,/g, '.'));
  };

  return (
    <>
      <Grid xs={12} md={6} lg={12}>
        <Box sx={cardStyle}>
          <Typography sx={{
            fontFamily: 'Poppins',
            fontSize: {
              xs: '12px',
              md: '16px',
            },
            textAlign: 'center',
            fontWeight: '700',
            maxWidth: {
              lg: '250px',
              xl: 'fit-content',
            },
            margin: '0 auto',
          }}
          >
            Todos os projetos submetidos na Bold
          </Typography>
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="donut"
          />
        </Box>
      </Grid>
      <Grid
        xs={12}
        md={6}
        lg={12}
        alignSelf={{
          xs: '',
          md: 'center',
          lg: 'flex-end',
        }}
      >
        <Box
          sx={{
            // Needed for card to use full size of container
            height: '100%',
          }}
        >
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            alignSelf="flex-end"
            sx={{
              // Needed for card to use full size of container
              height: '100%',
            }}
          >
            <Grid
              xs={12}
              lg={6}
              alignSelf={{
                md: 'center',
                lg: 'flex-end',
              }}
            >
              <Box sx={{
                ...cardStyle,
                padding: '1rem',
                paddingBottom: '2rem',
                paddingTop: '2rem',
              }}
              >
                <Typography sx={{
                  fontFamily: 'Poppins',
                  fontSize: {
                    xs: '12px',
                    md: '16px',
                    lg: '11px',
                    xl: '15px',
                  },
                  fontWeight: '700',
                }}
                >
                  Rendimento total
                </Typography>
                <Typography sx={{
                  fontSize: {
                    xs: '16px',
                    md: '20px',
                    lg: '14px',
                    xl: '20px',
                  },
                  fontWeight: 'bolder',
                  color: theme.palette.primary.main,
                }}
                >
                  {donutData.totalValueMonth}
                </Typography>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '0.5rem',
                }}
                >
                  <div>
                    {monthDifferenceToNumber() >= 0
                      && (
                        <Avatar
                          sx={{
                            mr: 1,
                            backgroundColor: 'green',
                            borderRadius: '0.5rem',
                            width: '2rem',
                            height: '2rem',
                          }}
                          variant="rounded"
                        >
                          <TrendingUp fontSize="medium" />
                        </Avatar>
                      )}
                    {monthDifferenceToNumber() < 0
                      && (
                        <Avatar
                          sx={{
                            mr: 1,
                            backgroundColor: 'red',
                            borderRadius: '0.5rem',
                            width: '2rem',
                            height: '2rem',
                          }}
                          variant="rounded"
                        >
                          <TrendingDownIcon fontSize="medium" />
                        </Avatar>
                      )}
                  </div>
                  <div>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: '12px',
                            md: '12px',
                            lg: '11px',
                          },
                          fontWeight: 'bolder',
                        }}
                        color="#000"
                      >
                        {donutData.monthDifference}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: '12px',
                            md: '12px',
                            lg: '11px',
                          },
                        }}
                        color="#000"
                      >
                        Comparativo ao mês anterior
                      </Typography>
                    </div>
                  </div>
                </Box>
              </Box>
            </Grid>
            <Grid
              xs={12}
              lg={6}
              alignSelf={{
                md: 'center',
                lg: 'flex-end',
              }}
            >
              <Box sx={{
                ...cardStyle,
                padding: '1rem',
                paddingBottom: '2rem',
                paddingTop: '2rem',
              }}
              >
                <Typography sx={{
                  fontFamily: 'Poppins',
                  fontSize: {
                    xs: '12px',
                    md: '16px',
                    lg: '11px',
                    xl: '15px',
                  },
                  fontWeight: '700',
                }}
                >
                  Novos projetos no mês
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: '16px',
                      md: '20px',
                      lg: '14px',
                      xl: '20px',
                    },
                    fontWeight: 'bolder',
                  }}
                  color={theme.palette.primary.main}
                >
                  {donutData.totalProjectsMonth}
                  {' '}
                  Novos projetos
                </Typography>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '0.5rem',
                }}
                >
                  <div>
                    {Number(donutData.monthProjectsDifference) >= 0
                      && (
                        <Avatar
                          sx={{
                            mr: 1,
                            backgroundColor: 'green',
                            borderRadius: '0.5rem',
                            width: '2rem',
                            height: '2rem',
                          }}
                          variant="rounded"
                        >
                          <TrendingUp fontSize="medium" />
                        </Avatar>
                      )}
                    {Number(donutData.monthProjectsDifference) < 0 && (
                      <Avatar
                        sx={{
                          mr: 1,
                          backgroundColor: 'red',
                          borderRadius: '0.5rem',
                          width: '2rem',
                          height: '2rem',
                        }}
                        variant="rounded"
                      >
                        <TrendingDownIcon fontSize="medium" />
                      </Avatar>
                    )}
                  </div>
                  <div>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: '12px',
                            md: '12px',
                            lg: '11px',
                          },
                          fontWeight: 'bolder',
                        }}
                        color="#000"
                      >
                        {donutData.monthProjectsDifference}
                        {' '}
                        Projetos
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: '12px',
                            md: '12px',
                            lg: '11px',
                          },
                        }}
                        color="#000"
                      >
                        Comparativo ao mês anterior
                      </Typography>
                    </div>
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
}

export default Donut;
